import onInView from '../utils/on-in-view'

export default ($: typeof window.jQuery, {
  containerSelector,
  activeClass = 'is-visible',
}: {
  containerSelector: string,
  activeClass?: string,
}) => {
  $(containerSelector)
    .each((idx, container) => {
      onInView(container, observer => {
        observer.disconnect()
        $(containerSelector).addClass(activeClass)
      }, {
        threshold: 0.5,
      })
    })
}
