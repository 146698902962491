import inViewCenter from '../utils/in-view-center'

export default ($: typeof window.jQuery, {
  containerSelector,
  itemSelector,
  pictureSelector,
  activeClass,
}: {
  containerSelector: string,
  itemSelector: string,
  pictureSelector: string,
  activeClass: string,
}) => {
  $(() => {
    const $window = $(window)

    $(containerSelector)
      .each((idx, container) => {
        const $container = $(container)
        const $items = $container.find(itemSelector)
        const $pictures = $items.find(pictureSelector)
        $window.on('scroll', () => {
          const activeElement = inViewCenter($items.toArray())
          $items.removeClass(activeClass)
          $(activeElement)
            .addClass(activeClass)
          const {
            top,
            bottom,
          } = container.getBoundingClientRect()
          const isFixed = top < 0 && bottom > window.innerHeight
          $pictures.toggleClass('is-fixed', isFixed)

          const {
            top: containerTop,
            height: containerHeight,
          } = container.getBoundingClientRect()
          const currentTop = -containerTop // negative value to reverse the actual value
          const scrollPercentage = Math.max(0, Math.min(1, (currentTop / Math.max(1, containerHeight - window.innerHeight))))
          container.style.setProperty('--scroll-progress', String(scrollPercentage))
        })
          .trigger('scroll')
      })
  })
}
