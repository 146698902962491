export default ($: typeof window.jQuery, {
  selector,
}) => {
  $(selector).each((idx, elem: HTMLDivElement) => {
    const parent = elem.parentNode as HTMLDivElement
    elem.innerHTML = `<span>${elem.innerHTML}</span>`
    const { width: elemWidth } = elem.firstElementChild.getBoundingClientRect()
    const fontSize = parseInt(window.getComputedStyle(elem).fontSize)
    elem.style.fontSize = `calc(var(--parent-width) * var(--fit-text-multiplier) * ${(fontSize / elemWidth)}px)`
    elem.innerHTML = elem.firstElementChild.innerHTML

    const onResize = () => {
      const { width: parentWidth } = parent.getBoundingClientRect()
      parent.style.setProperty('--parent-width', String(parentWidth))
    }
    $(window).on('resize', onResize)
    onResize()
  })
}
