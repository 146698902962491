import inViewCenter from '../utils/in-view-center'

export default ($: typeof window.jQuery) => {
  const $window = $(window)

  $('#pizza-campaign').each((idx, root) => {
    const $root = $(root)

    // implement css vars
    $window.on('resize', () => {
      const { top } = root.getBoundingClientRect()
      root.style.setProperty('--header-height', `${top + window.scrollY}px`)
      root.style.setProperty('--vh', `${(window.innerHeight * 0.01).toFixed(2)}px`)
    }).trigger('resize')

    // implement themes
    const $items = $root.find('[data-theme]')
    $window.on('scroll', () => {
      const activeElem = inViewCenter($items.toArray())

      const theme = activeElem.dataset.theme ?? 'default'
      const currentClassName = root.className ?? ''
      currentClassName
        .split(' ')
        .filter(c => c.startsWith('theme--') && c !== `theme--${theme}`)
        .forEach(c => $root.removeClass(c))
      $root.addClass(`theme--${theme}`)
    }).trigger('scroll')
  })
}
