import fitText from '../utils/fit-text'
import onInView from '../utils/on-in-view'
import staggerText from '../utils/stagger-text'

export default ($: typeof window.jQuery) => {
  $('.page-header').each((idx, elem) => {
    const $this = $(elem)
    fitText($, {
      selector: $this.find('.page-header__title'),
    })

    const stagger = staggerText($, {
      selector: $this.find('.page-header__title'),
      containerClass: 'text-stagger__container',
      itemClass: 'text-stagger__item',
      visibleClass: 'is-visible',
      duration: 250,
      immediate: false,
    })
    onInView(elem, observer => {
      observer.disconnect()
      stagger()
      $this.addClass('is-inview')
    }, {
      threshold: 0.1,
    })
  })
}
