// @ts-ignore
import type jQuery from '@types/jquery'
import '../styles/styles.scss'
import carousel from './components/carousel'
import ctaSection from './components/cta-section'
import createIngredientsCarousel from './components/ingredients-carousel'
import pageHeader from './components/page-header'
import parallax from './components/parallax'
import revealText from './components/reveal-text'
import root from './components/root'
import stickyText from './components/sticky-text'
import swipeDetector from './utils/swipe-detector'

declare global {
  interface Window {
    jQuery: typeof jQuery;
    $: typeof jQuery;
  }
}

function waitFor (predicate: () => boolean, callback: () => void, interval: number = 50): unknown {
  return predicate()
    ? callback()
    : setTimeout(() => waitFor(predicate, callback, interval), interval)
}

waitFor(
  () => 'jQuery' in window,
  () => window.jQuery($ => {
    swipeDetector($)
    root($)
    pageHeader($)
    createIngredientsCarousel($, {
      containerSelector: '.ingredients-carousel',
      itemSelector: '.ingredients-carousel-item',
      pictureSelector: '.ingredients-carousel-item__picture-container',
      activeClass: 'is-active',
    })
    stickyText($, {
      containerSelector: '.sticky-text',
      itemSelector: '.sticky-text__content-container',
      pictureSelector: '.sticky-text__figure',
      dataSelector: 'item',
      activeClass: 'is-active',
    })
    revealText($, {
      containerSelector: '.reveal-text-container',
    })
    ctaSection($, {
      containerSelector: '.text-section',
    })
    carousel($, {
      containerSelector: '.carousel',
    })
    parallax($, { containerSelector: '.parallax-container' })
  }),
)
