export default ($: typeof window.jQuery, {
  containerSelector,
}: {
  containerSelector: string,
  activeClass?: string,
}) => {
  $(containerSelector)
    .each((idx, container) => {
      const $carouselInner = $(container)
        .find('.carousel__inner')
      const $items = $carouselInner.find('.carousel__item')
      const $indicators = $(container)
        .find('.carousel__indicator')

      function next ($el: ReturnType<typeof window.jQuery<HTMLElement>>) {
        if ($el.next().length) {
          return $el.next()
        }
        return $items.first()
      }

      function prev ($el: ReturnType<typeof window.jQuery<HTMLElement>>) {
        if ($el.prev().length) {
          return $el.prev()
        }
        return $items.last()
      }

      const handleSwipe = (e: Event) => {
        let $newSeat: ReturnType<typeof window.jQuery<HTMLElement>>
        const $el = $(container)
          .find('.is-ref')
        $el.removeClass('is-ref')

        if (e.type === 'swipeLeft') {
          $newSeat = next($el)
          $carouselInner.removeClass('is-reversing')
        } else {
          $newSeat = prev($el)
          $carouselInner.addClass('is-reversing')
        }

        $newSeat.addClass('is-ref')
          .css('order', 1)
          .removeClass('is-active')

        for (let i = 2; i <= $items.length; i++) {
          $newSeat = next($newSeat)
            .removeClass('is-active')
            .css('order', i)

          if (i === 2) {
            $newSeat.addClass('is-active')
          }
        }

        const itemIndex = $items.filter('.is-active')
          .data('item') as number
        $indicators
          .removeClass('is-active')
          .filter(`[data-item="${itemIndex}"]`)
          .addClass('is-active')

        $carouselInner.removeClass('is-set')
        return setTimeout(() => {
          return $carouselInner.addClass('is-set')
        }, 50)
      }

      // eslint-disable-next-line
      $(container)
        // @ts-ignore
        .swipeDetector()
        .on('swipeLeft.sd', handleSwipe)
        .on('swipeRight.sd', handleSwipe)
    })
}
