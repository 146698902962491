export default function inViewCenter (elems: Array<HTMLElement>): HTMLElement {
  const windowHeight = window.innerHeight
  let distance = Number.POSITIVE_INFINITY
  let activeElement: HTMLElement
  const halfHeight = windowHeight / 2
  elems.forEach((elem, idx) => {
    const rect = elem.getBoundingClientRect()

    const top = rect.top - halfHeight
    const bottom = top + rect.height
    const closest = top <= 0 && bottom > 0 ? 0 : Math.min(Math.abs(top), Math.abs(bottom))
    if (closest < distance) {
      distance = closest
      activeElement = elem
    }
  })
  return activeElement
}
