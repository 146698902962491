const TEXT_NODE = 3
export default ($: typeof window.jQuery, {
  selector,
  containerClass,
  itemClass,
  visibleClass,
  delay,
  duration,
  immediate = true,
}: {
  selector: unknown,
  containerClass: string,
  itemClass: string,
  visibleClass: string,
  delay?: number,
  duration?: number,
  immediate?: boolean
}) => {
  $(selector).each((idx, elem: HTMLDivElement) => {
    const children = [] as Node[]
    elem.childNodes.forEach((node: Node) => {
      if (node.nodeName === 'BR') return
      if (node.nodeType === TEXT_NODE) {
        const container: HTMLSpanElement = $(`<span class="${containerClass}"></span>`)[0]

        node.textContent.split('').forEach(c => {
          if (c === ' ') {
            container.insertAdjacentHTML('beforeend', '&nbsp;')
          } else {
            container.insertAdjacentHTML('beforeend', `<span class="${itemClass}">${c}</span>`)
          }
        })
        node = container
      }
      children.push(node)
    })
    elem.replaceChildren(...children)
  })
  const callback = () => {
    $(selector).each((idx, elem: HTMLDivElement) => {
      const $elements = $(elem).find(`.${itemClass}`)
      const _delay = delay ?? (duration / $elements.length)

      $elements.each((idx, child: HTMLDivElement) => {
        setTimeout(() => {
          $(child).addClass(visibleClass)
        }, idx * _delay)
      })
    })
  }
  if (immediate) {
    callback()
  }
  return callback
}
