import inViewCenter from '../utils/in-view-center'

export default ($: typeof window.jQuery, {
  containerSelector,
  itemSelector,
  pictureSelector,
  activeClass,
  dataSelector,
}: {
  containerSelector: string,
  itemSelector: string,
  pictureSelector: string,
  activeClass: string,
  dataSelector: string,
}) => {
  const $window = $(window)

  $(containerSelector)
    .each((idx, container) => {
      const $container = $(container)
      const $items = $container.find(itemSelector)
      const $pictures = $container.find(pictureSelector)
      $window.on('scroll', () => {
        const activeElement = inViewCenter($pictures.toArray())
        $pictures.removeClass(activeClass)
        const itemIndex = $(activeElement)
          .data(dataSelector) as number
        $items.removeClass(activeClass)
        $items.filter(`[data-${dataSelector}="${itemIndex}"]`)
          .addClass(activeClass)
      })
        .trigger('scroll')
    })
}
