import onInView from '../utils/on-in-view'

export default ($: typeof window.jQuery, {
  containerSelector,
}: {
  containerSelector: string,
}) => {
  $(containerSelector)
    .each((idx, container) => {
      const $container = $(container)
      if ($container.is(':hidden')) {
        return false
      }

      let scrolled: number
      $(window)
        .on('scroll', () => {
          scrolled = $(window)
            .scrollTop()

          $container.find('.parallax-item')
            .each((idx, parallaxItem) => {
              const $parallaxItem = $(parallaxItem)
              const initY = $parallaxItem.offset().top
              const height = $parallaxItem.height()

              onInView(container, () => {
                const diff = scrolled - initY
                const ratio = Math.round((diff / height) * 100)
                $parallaxItem.css('transform', `translateY(${-ratio}px)`)
              }, {})
            })
        })
        .trigger('scroll')
    })
}
