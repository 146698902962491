import onInView from '../utils/on-in-view'

export default ($: typeof window.jQuery, {
  containerSelector,
  itemSelector = '.reveal-text',
  activeClass = 'is-visible',
}: {
  containerSelector: string,
  itemSelector?: string,
  activeClass?: string,
}) => {
  $(containerSelector)
    .each((idx, container) => {
      const $revealItems = $(container)
        .find(itemSelector)

      onInView(container, observer => {
        observer.disconnect()
        $revealItems.addClass(activeClass)
      }, {
        threshold: 0.33,
      })
    })
}
